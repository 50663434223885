.bubble {
    border-radius: 50%;
    width: 145px;
    height: 145px;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 6px 18px 0 rgba(0, 29, 124, 0.08), 0 6px 5px -3px rgba(0, 0, 0, 0.12);
}
.bubble:hover {
    border: 2px solid yellow;
    z-index: 10;
}


.bubble:active {
    filter: grayscale(30%) !important;
    box-shadow: none;
}

.bubble-text {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

.invisible {
    visibility: hidden;
}