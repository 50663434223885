html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  height: calc(100vh - 60px);
  width: 100%;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  background-color: #282828;
  color:#fff;
}

#bubbles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100% - 120px);
  width: 100%;
  background-color: #b3b3b3;
}

#bottom-bar {
  height: 120px;
  width: 100%;
  background-color: #282828;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#round-number {
  width:'100%';
  text-align: center;
  margin: 0;
  font-size: 24px;
  color:#fff;
}

#startButton {
  font-size: 16px;
  padding:16px;
  border-radius: 32px;
}

button.menuButton {
  padding: 8px;
  border-radius: 16px;
  color:#fff;
  background-color: dodgerblue;
  border: 0px;
}
button.menuButton:active {
  filter: grayscale(30%) !important;
}