.timebox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.timebarParent {
    background-color: dimgrey;
    width: 400px;
    height:24px;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
}

.timebar {
    height: 8px;
    position:relative;
}

.targetText {
    color:greenyellow;
    margin: 0;
    font-size: 44px;
    font-weight: 700;
    width:100%;
    text-align: center;
}